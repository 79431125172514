.header {
    padding: 0;
}

.km-header {
    color: white;
    width: 80%;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 16px;
}

.km-header-logo {
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    color: white;
}

.km-header-right {
    text-align: right;
    height: 100%;
    margin: 0 8px;
}

.km-header-right-item {
    cursor: pointer;
    /*padding: 23px 12px;*/
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    vertical-align: middle;
    height: 100%;
}

.km-container {
    width: 80%;
    margin: 0 auto;
}

.kd-content {
    margin-top: 20px;
    padding: 16px;
    background-color: white;
}

.kd-page-header {
    background-color: white;
    margin-top: 20px;
}